<template>
  <b-container
    class="d-flex flex-column align-items-center justify-content-center mx-0 px-0 root-layout"
    fluid
  >
    <div>
      <b-img
        src="@/assets/Figa_reglog.png"
        class="Figalogo-img"
        alt="Figalogo"
      ></b-img>
    </div>

    <b-card
      body-class="text-center"
      style="max-width: 70rem; border-radius: 14.4px"
      class="mt-4"
    >
      <div class="login-view">
        <b-img
          src="@/assets/Administrator-icon.png"
          class="logo-img"
          alt="Finance"
        ></b-img>

        <b-row
          class="d-flex flex-row align-items-center justify-content-center"
          no-gutters
        >
          <b-col>
            <h3
              class="text-center text-main-green font-primary font-weight-bold"
            >
              Master Admin Registration
            </h3>

            <h5
              class="text-center text-main-green font-primary font-weight-normal"
            >
              Please enter in your master credentials to create a master admin
            </h5>
          </b-col>
        </b-row>

        <form id="register-form">
          <b-row
            class="d-flex flex-row align-items-center justify-content-center"
            no-gutters
          >
            <b-col>
              <FormInput
                groupId="user-name-group"
                id="user-name-group-input"
                class="text-prime-gray rounded-2"
                label="Master Key"
                v-model="masterkey"
                placeholder="Master Key"
                isRequired
                :whiteBG="false"
                trim
                form="register-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-center justify-content-center"
            no-gutters
          >
            <b-col>
              <FormInput
                groupId="user-name-group"
                id="user-name-group-input"
                class="text-prime-gray rounded-2"
                label="User Name"
                v-model="username"
                placeholder="User Name"
                isRequired
                :whiteBG="false"
                trim
                form="register-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-center justify-content-center"
            no-gutters
          >
            <b-col>
              <FormInput
                groupId="user-name-group"
                id="user-name-group-input"
                class="text-prime-gray rounded-2"
                label="Email Account"
                :state="emailError.status"
                :invalidFeedback="`${emailError.message}`"
                v-model="email"
                placeholder="user@example.com"
                isRequired
                :whiteBG="false"
                trim
                form="register-form"
                type="text"
              ></FormInput>
            </b-col>
          </b-row>
          <b-row
            class="d-flex flex-row align-items-center justify-content-center"
            no-gutters
          >
            <b-col>
              <FormInputGroup
                groupId="password-group"
                id="password-group-input"
                class="input-field"
                v-model="password"
                label="Password"
                :state="passwordError.status"
                :invalidFeedback="`${passwordError.message}`"
                placeholder="Minimum 6 characters"
                isRequired
                :whiteBG="false"
                :type="showPassword ? 'text' : 'password'"
                form="register-form"
                isAppend
              >
                <template v-slot:append>
                  <b-button
                    class="px-3 px-md-4 rounded-0"
                    variant="transparent"
                    @click="showPassword = !showPassword"
                  >
                    <b-icon
                      :icon="showPassword ? 'eye-slash' : 'eye'"
                      aria-label="Help"
                    ></b-icon>
                  </b-button>
                </template>
              </FormInputGroup>
            </b-col>
          </b-row>

          <b-row flex-row align-items-center justify-content-center>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
            >
              <FormButton
                isBlock
                isPill
                variant="main-green"
                class="font-weight-normal text-secondary mt-2 login-button"
                type="submit"
                :class="{ 'btn-disabled': !isFormValid }"
                :disabled="!isFormValid"
                @click.native="RegisterFn"
                ><span class="text-white">Register</span></FormButton
              >
            </b-col>
            <b-col
              d-flex
              flex-row
              align-items-center
              justify-content-center
              align-self="center"
            >
              <b-link
                to="/"
                class="font-primary font-weight-normal text-main-green"
                >Already Registered?</b-link
              >
            </b-col>
          </b-row>
        </form>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import FormInput from "@/components/Form/FormInput";
import FormInputGroup from "@/components/Form/FormInputGroup";
import FormButton from "@/components/Form/FormButton";
import CheckEmail from "@/util/CheckEmail";

import axios from "axios";

export default {
  name: "Register",
  components: {
    FormInput,
    FormInputGroup,
    FormButton,
  },
  data() {
    return {
      masterkey: "",
      username: "",
      email: "",
      password: "",
      showPassword: false,

      emailError: {
        status: null,
        message: "",
      },
      passwordError: {
        status: null,
        message: "",
      },
      loginError: {
        status: false,
        message: "",
      },
    };
  },
  watch: {
    email(value) {
      if (value) {
        if (CheckEmail(value) == true) this.emailError.status = true;
        else {
          this.emailError.status = false;
          this.emailError.message = "Please enter a valid email address";
        }
      } else {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your email address";
      }
    },
    password(value) {
      if (value) {
        if (value.length < 6) {
          this.passwordError.status = false;
          this.passwordError.message =
            "A minimum length of 6 characters needed";
        } else {
          this.passwordError.status = true;
        }
      } else {
        this.passwordError.status = false;
        this.passwordError.message = "Please fill in your password";
      }
    },
  },
  computed: {
    isFormValid() {
      return this.email !== "" && this.password !== "";
    },
  },
  methods: {
    async RegisterFn() {
        try {
          const payload = {
            email: this.email,
            password: this.password,
            name: this.username,
            master_key: this.masterkey
          }
          const response = await axios.post("/admin/register", payload);
          if (response.status == 200) {
            this.$bvToast.toast('Admin Registered successfully', {
              title:'Successfully Registered',
              toaster: 'b-toaster-bottom-right',
              variant:'success',
          solid: true
        })
            console.log(response);
            localStorage.setItem("figa_name", response.data.name);
            localStorage.setItem("figa_access_token", response.data.access_token);
            localStorage.setItem("figa_refresh_token", response.data.refreshToken);
            this.$router.push("/dashboard");
          }else{
            this.$bvToast.toast('Fail to register', {
              title:'Registration failed',
              toaster: 'b-toaster-bottom-right',
              variant:'danger',
             
          solid: true
        })
          }
          return response;
        } catch (error) {
          console.log(error.response);
        
          this.$bvToast.toast('Email has already been registered', {
            title:'Registration failed',
              toaster: 'b-toaster-bottom-right',
              variant:'danger',
              noCloseButton : true,
          solid: true
        })
          throw error;
        }
    },
    validateForm() {
      if (!this.email) {
        this.emailError.status = false;
        this.emailError.message = "Please fill in your email address";
      }

      if (!this.password) {
        this.passwordError.status = false;
        this.passwordError.message = "Please fill in your password";
      }

      if (this.password < 6) {
        this.passwordError.status = false;
        this.passwordError.message = "A minimum length of 6 characters needed";
      }
    },
  },
};
</script>

<style scoped>
.root-layout {
  position: relative;
  min-height: 100vh;
  width: 100%;
  top: 0;
  left: 0;

  background-color: #ffe400;
}

.logo-img {
  align-items: center;
  width: 54px;
  height: 54px;
}
.root-layout .Figalogo-img {
  align-items: center;
  width: 180px;
 height: 60px;
}
.login-view {
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 36px;
  padding-bottom: 50px;
}
h5 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  padding-bottom: 25.6px;

  /* Grey 2 */

  color: #828282;
}
h3 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  padding-top: 14px;
  /* Color/Green */

  color: #0b9b7d;
}

.login-view .login-button {
  width: 162px;
  height: 50px;
  background: #0b9b7d;
  border-radius: 30px;
}
</style>